import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

// This value should be approx 90% of the scss variable $switch-animation-ms in _form.scss
export const SWITCH_ANIMATION_MS = 360;

/**
 * Example:
 *
 *   oz-switch([(ngModel)]="showIcons", (ngModelChange)="handleChange()", [id]="'showIcons'")
 */
@Component({
  selector: "oz-switch",
  templateUrl: "./switch.component.pug",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  /**
   * Supports optional on / off values, defaults to true (on) / false (off)
   */
  @Input() valueOff = false;
  @Input() valueOn = true;
  @Input() waitForAnimation = false;
  @Input() disabled = false;

  private onChange: (_: any) => {};
  private onTouched: (_: any) => {};
  public isChecked: boolean;
  public animationMS = SWITCH_ANIMATION_MS;

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(isChecked: any): void {
    this.isChecked =
      isChecked === "0" || isChecked === "false" || isChecked === this.valueOff
        ? false
        : !!isChecked;
  }

  emitChange() {
    if (!this.waitForAnimation) {
      this.doOnChange();
      return;
    }

    setTimeout(() => {
      this.doOnChange();
    }, SWITCH_ANIMATION_MS);
  }

  doOnChange() {
    this.onChange(this.isChecked ? this.valueOn : this.valueOff);
  }
}
